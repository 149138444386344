<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <!-- SHOW BOOKING POPUP -->
    <booking-preview 
        ref="preview" 
        :bookingPreviewActive.sync="bookingPreviewActive" 
        :currentBooking="currentBooking"
        :isLeadBooking="true"
    />

    <vs-prompt v-if="currentBooking" :title="currentBooking.state_id == 8 ? $t('unblockDate.header') : $t('blockDate.header')" class="export-options" :color="currentBooking.state_id == 8 ? 'success' : 'danger'" @accept="blockDate" :accept-text="currentBooking.state_id == 8 ? $t('unblockDate.block') : $t('blockDate.block')" :cancel-text="$t('blockDate.cancel')" :active.sync="bookingBlockPromptActive">
      <p>{{currentBooking.state_id == 8 ? $t('unblockDate.bodyCustomerDate', {bookingOption:currentBooking.receipt.booking_option.name, date:currentBooking.start_date}) : $t('blockDate.bodyCustomerDate', {bookingOption:currentBooking.receipt.booking_option.name, date:currentBooking.start_date}) }}</p>
      <p>{{currentBooking.state_id == 8 ? $t('unblockDate.confirmation') : $t('blockDate.confirmation')}}</p>
      <vs-alert color="warning" icon="new_releases" class="my-4">
        <small>{{currentBooking.state_id == 8 ? $t('unblockDate.note') : $t('blockDate.note')}}</small>
      </vs-alert>
    </vs-prompt>


    <discount-coupons-form-popup :discountCouponPopupActive.sync="sendOfferToCustomerPromptActive" :currentDiscountCoupon="currentDiscountCoupon" :isSpecificToCustomer="true" :booking="currentBooking" @getLeads="getLeads"/>

    <special-offers-popup :specialOffersPopupActive.sync="specialOffersPopupActive" :specialOffer="specialOffer" :booking="currentBooking" @getLeads="getLeads"/>

    <vs-table
        v-if="!isPhoneScreen"
        class="mb-5"
        :sst="true" 
        ref="leadsTableRef"
        @change-page="handleChangePage"
        v-model="selected"
        pagination
        :total="parseInt(tableConfig.total)"
        :max-items="parseInt(tableConfig.perPage)" 
        :data="leads"
        @selected="showData"
        >

      <template  slot="thead">
          <template>
              <vs-th v-for="(col, index) in generalInfoColumnsTitles" :key="index" :sort-key="col.sortKey">
              <p class="table-head font-medium truncate">{{ col.title }}</p>
              </vs-th>
          </template>
      </template>
      

      
      <template slot-scope="{data}">
        <tbody>
          <template>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">
                <span class="flex flex-row whitespace-no-wrap">
                  
                  <vs-button class="mx-1 action-btn" type="border" icon-pack="feather" icon="icon-info" :size="isPhoneScreen ? 'small' : 'default'" @click="showData(tr)"  color="#adadad">
                    {{ $t("bookingsLeads.showBooking")}}
                  </vs-button>
                  <vs-button v-if="tr.special_offers.length > 0" class="mx-1 action-btn" type="border" icon-pack="feather" icon="icon-info" :size="isPhoneScreen ? 'small' : 'default'" @click.stop="showSpecialOffers(tr)"  color="#34abeb">
                    {{ $t("bookingsLeads.showSpecialOffers")}}
                  </vs-button>
                  <vs-button v-else class="mx-1 action-btn" type="border" icon-pack="feather" icon="icon-percent" :size="isPhoneScreen ? 'small' : 'default'" @click.stop="sendOfferToCustomerBtn(tr)" color="success">
                    {{ $t("bookingsLeads.sendOfferToCustomer")}}
                  </vs-button>
                  <vs-button 
                    v-if="tr.start_date > today"
                    class="mx-1 action-btn" type="border" icon-pack="feather" :icon="tr.state_id == 8 ? 'icon-check' : 'icon-minus-circle' " :size="isPhoneScreen ? 'small' : 'default'" @click.stop="blockDateBtn(tr)" :color="tr.state_id == 8 ? 'success' : 'danger'"
                  >
                    {{ tr.state_id == 8 ? $t("bookingsLeads.unblockDate") : $t("bookingsLeads.dateIsBooked") }}
                  </vs-button>
                </span>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">{{tr.receipt.local_final_price_without_discount ? tr.receipt.local_final_price_without_discount.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</p>
              </vs-td>
              <vs-td>
                <p class="booking-date truncate">{{ tr.start_date | moment("DD-MM-YYYY")}}</p>
              </vs-td>
              <vs-td>
                <p class="booking-date truncate">{{ tr.booked_at | moment("DD-MM-YYYY")}}</p>
              </vs-td>
              <vs-td>
                  <p class="customer-phone">{{ tr.customer_name ? tr.customer_name.substring(0, 2)+"*****" : '' }}</p>
              </vs-td>
            </vs-tr>
          </template>
        </tbody>
      </template>
        
    </vs-table>
    <vx-card v-else>
        <div class="container-fluid mt-2 px-0 mb-5">
            <div class="grid grid-cols-1 gap-3" v-if="leads && leads.length > 0">
                <div v-for="lead in leads" :key="lead.id">
                    <vs-card class="cardx mb-5" fixed-height>
                      <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-3">
                        <h4 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-user"></i> {{$t('bookingsLeads.name')}}</h4>
                        <h4 class="text-lg lg:text-2xl">{{lead.customer_name ? lead.customer_name.substring(0, 2)+"*****" : ''}}</h4>
                      </div>
                      <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-3">
                        <h4 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-calendar-check"></i> {{$t('bookingsLeads.eventDate')}}</h4>
                        <h4 class="text-lg lg:text-2xl">{{lead.start_date ? lead.start_date : ''}}</h4>
                      </div>
                      <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-3">
                        <h4 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-clock"></i> {{$t('bookingsLeads.bookedAt')}}</h4>
                        <h4 class="text-lg lg:text-2xl">{{lead.booked_at ? lead.booked_at : ''}}</h4>
                      </div>
                      <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5">
                        <h3 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-dollar-sign ml-1 mr-1"></i> {{$t('bookingsLeads.price')}}</h3>
                        <div>
                            <h3 class="text-danger text-lg lg:text-2xl">
                                {{lead.receipt.local_final_price_without_discount ? lead.receipt.local_final_price_without_discount.toLocaleString() : 0 + ' '}} {{$t("SAR")}}
                            </h3>
                            <div class="vx-col p-0 w-full flex flex-wrap justify-center">
                                <small class="very-small-note">{{$t('vatIncluded')}}</small>
                            </div>
                        </div>
                      </div>
                      <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-3">
                          <vs-button v-if="lead.special_offers.length > 0" class="action-btn" type="border" icon-pack="feather" icon="icon-percent" :size="isPhoneScreen ? 'small' : 'default'" @click.stop="showSpecialOffers(lead)" color="#34abeb">
                            {{ $t("bookingsLeads.showSpecialOffers")}}
                          </vs-button>
                          <vs-button v-else class="action-btn" type="border" icon-pack="feather" icon="icon-percent" :size="isPhoneScreen ? 'small' : 'default'" @click.stop="sendOfferToCustomerBtn(lead)" color="success">
                            {{ $t("bookingsLeads.sendOfferToCustomer")}}
                          </vs-button>
                          <vs-button 
                            v-if="lead.start_date > today" 
                            class="action-btn" type="border" icon-pack="feather" :icon="lead.state_id == 8 ? 'icon-check' : 'icon-minus-circle' " :size="isPhoneScreen ? 'small' : 'default'" @click.stop="blockDateBtn(lead)" :color="lead.state_id == 8 ? 'success' : 'danger'">
                            {{ lead.state_id == 8 ? $t("bookingsLeads.unblockDate") : $t("bookingsLeads.dateIsBooked") }}
                          </vs-button>
                      </div>
                      <div class="vx-col p-0 w-full flex flex-wrap justify-center mt-3">
                          <vs-button class="action-btn w-full" type="border" icon-pack="feather" icon="icon-info" :size="isPhoneScreen ? 'small' : 'default'" @click="showData(lead)"  color="#adadad">
                            {{ $t("bookingsLeads.showBooking")}}
                          </vs-button>
                      </div>
                    </vs-card>
                </div>
            </div>
            <div class="row w-full mt-10 lg:mt-5 flex flex-wrap justify-center" v-else-if="leads && leads.length == 0">
              <h5 class="mt-3 mr-2 text-center gray-note">{{$t('bookingOptionPreview.noBookingOptions')}}</h5>
            </div>
        </div>
        <div class="container-fluid mt-2 px-0 mb-5">
          <div class="vx-col p-0 w-full mt-3">
            <vs-pagination :total="Math.ceil(tableConfig.total / tableConfig.perPage)" :max="5" v-model="currentx" @input="handleChangePage"></vs-pagination>
          </div>
          <!-- <vs-pagination :total="Math.ceil(tableConfig.total / tableConfig.perPage)" v-model="currentx"></vs-pagination> -->
        </div>
    </vx-card>
    
  </div>
</template>

<script>
import moment from 'moment'
import axios from '@/axios'
import DiscountCouponsFormPopup from '@/views/components/qosoor-components/DiscountCoupons/DiscountCouponsFormPopup.vue'
import SpecialOffersPopup from '@/views/components/qosoor-components/DiscountCoupons/SpecialOffersPopup.vue'
export default {
  components: {
    DiscountCouponsFormPopup,
    SpecialOffersPopup
  },
  data () {
    return {
      currentx: 1,
      selected: [],
      leads: [],
      generalInfoColumnsTitles : [        
          {title: '', key: ""},
          {title: this.$t('bookingsLeads.price'), key: "price"},
          {title: this.$t('bookingsLeads.eventDate'), key: "event_date"},
          {title: this.$t('bookingsLeads.bookedAt'), key: "booked_at"},
          {title: this.$t('bookingsLeads.name'), key: "name"},
          // {title: '', key: "actions"},
      ],
      bookingPreviewActive: false,
      currentBooking: null,
      tableConfig: {
        total: 0,
        perPage: 10
      },
      bookingBlockPromptActive: false,
      sendOfferToCustomerPromptActive: false,
      currentDiscountCoupon: null,

      specialOffersPopupActive: false,
      specialOffer: {},

    }
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    isPhoneScreen() {
        return /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    today() {
      return moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    getLeads(page=1) {
      let tableCurrentx = this.$refs.leadsTableRef ? this.$refs.leadsTableRef.currentx : null;
      if(tableCurrentx)
        page = tableCurrentx > this.currentx ? tableCurrentx : this.currentx;

      let query = {
        page: page,
        perPage: this.tableConfig.perPage
      }
      axios.get(`/venues/${this.currentVenueId}/bookings/leads`, {params: query})
        .then((response) => {
            this.leads = response.data.leads.data
            this.tableConfig.total = response.data.total
        })
        .catch((error) => {})
        .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    },
    handleChangePage(page) {
      this.getLeads(page);
    },
    showData(data) {
      this.sendLogToAdminDashboard('showBooking');
      this.bookingPreviewActive = true;
      this.currentBooking = data;
    },
    showSpecialOffers(data) {
      if(data.special_offers.length > 0) {
        this.specialOffersPopupActive = true;
        this.specialOffer = data.special_offers[data.special_offers.length - 1];
        this.currentBooking = data;

      }

    },
    sendOfferToCustomerBtn(data) {
      this.sendLogToAdminDashboard('sendOfferToCustomerBtn');
      let bookingNumberLast3Digits = data.booking_number.substring(data.booking_number.length - 3, data.booking_number.length);
      let rand3Chars = this.generateRandomString();
      let rand3Nums = this.generateRandomNumber();
      let name = `كوبون خصم لعميل ${bookingNumberLast3Digits} ${data.receipt.booking_option ? 'على '+data.receipt.booking_option.name : ''} في تاريخ ${data.start_date}`;
      this.currentDiscountCoupon = {
        discount_type: 'fixed',
        name: name,
        code: `${bookingNumberLast3Digits}${rand3Chars}${rand3Nums}`,
        max_usage_count: 1,
      };
      this.sendOfferToCustomerPromptActive = true;
      this.currentBooking = data;
    },
    blockDateBtn(data) {
      this.sendLogToAdminDashboard('blockDateBtn');
      this.bookingBlockPromptActive = true;
      this.currentBooking = data;
    },
    blockDate() {
      this.sendLogToAdminDashboard('blockDate');
      this.$store.dispatch('loader/loaderOn')

      let data = {
        venueId: this.currentVenueId,
        booking: this.currentBooking,
        action: this.currentBooking.state_id == 8 ? 'unblock' : 'block',
      }
      // this.$store.dispatch('dataList/addItem', {venueId: this.currentVenueId, request: request, stateId: this.currentBooking.state_id, bookingId: this.currentBooking.id, receiptId: this.currentBooking.receipt.id})
      this.$store.dispatch('dataList/changeLeadBookingState', data)
        .then(response => {
          const index = this.leads.findIndex(lead => lead.id === response.data.lead.id)

          if(index !== -1) {
            this.leads.splice(index, 1, response.data.lead)
          }

          this.bookingBlockPromptActive = false;
          this.$vs.notify({
            color:'success',
            title: this.$t('updateDone'),
            text: data.action == 'block' ? 'تم حجب التاريخ بنجاح' : 'تم إلغاء الحجب عن التاريخ'
          })
        })
        .catch(function(error){ 
          this.$vs.notify({
            color:'danger',
            title: this.$t('error'),
            text: 'حدث خطأ ما، نرجو التواصل مع الإدارة'
          })
          console.error(error)
        }.bind(this))
        .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    },
    whatsappCustomer(data) {
      this.sendLogToAdminDashboard('whatsapp');

      let customer_phone = data.customer_phone_number;
      window.open(`https://wa.me/${customer_phone}`, '_blank');
    },
    sendLogToAdminDashboard(action) {
      let query = {
        action: action,
      }
      axios.get(`/venues/${this.currentVenueId}/bookings/leads/logAction`, {params: query})
    },
  },
  created () {
    this.$store.dispatch('loader/loaderOff')
    this.getLeads();
    this.sendLogToAdminDashboard('pageLoad');
  }
}
</script>

<style lang="scss">

</style>
