<template>
    <div v-if="specialOffer">
        <vs-popup class="holamundo"  title="العروض المرسلة" :active.sync="isActive" @close="closePopup">
            <div class="container w-full">
                <discount-coupons-form 
                        :isEditDiscountCoupon="true" 
                        :editFromLeads="true" 
                        :booking="booking" 
                        :currentDiscountCoupon="specialOffer" 
                        @closePopup="closePopup"
                        @getLeads="getLeads"
                    />                    
                <!-- <div class="vx-col w-full md:mb-2" v-for="offer in specialOffers" :key="offer.id">
                    <discount-coupons-form 
                        :isEditDiscountCoupon="true" 
                        :editFromLeads="true" 
                        :booking="booking" 
                        :currentDiscountCoupon="offer" 
                        @closePopup="closePopup"
                    />                    
                    <vs-divider class="my-10"/>
                </div> -->
            </div>
        </vs-popup>
    </div>
</template>

<script>

import DiscountCouponDetails from '@/views/components/qosoor-components/DiscountCoupons/DiscountCouponDetails.vue'
import DiscountCouponsForm from '@/views/components/qosoor-components/DiscountCoupons/DiscountCouponsForm.vue';
export default {
    name: 'special-offers-popup',
    props: {
        specialOffersPopupActive: {
            type: Boolean,
            defult: false
        },
        specialOffer: {
            type: Object,
            defult: null
        },
        booking: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            latestSpecialOffer: {},
        }
    },
    components: {DiscountCouponDetails, DiscountCouponsForm},
    computed: {
        isActive: {
            get() {
                return this.specialOffersPopupActive
            },
            set(newState) {
                return newState
            }
        },
        
    },
    created () {},
    methods: {
        closePopup() {
            this.$emit('update:specialOffersPopupActive', false);
        },
        getLeads() {
            this.$emit('getLeads');
        },
    },
    watch: {
    }
}
</script>

<style lang="css">

</style>
