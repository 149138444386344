<template>
    <div v-if="currentDiscountCoupon != null">
        <vs-popup class="holamundo"  :title="!isEditDiscountCoupon ? $t('discountCouponForm.addDiscountCoupon') : $t('discountCouponForm.editDiscountCoupon') +' | '+ currentDiscountCoupon.name" :active.sync="isActive" @close="closePopup">
            <div class="container w-full">
                <div class="flex flex-wrap">
                    <div class="vx-col w-full md:mb-2" v-if="isSpecificToCustomer && booking != null">
                        <vs-alert color="warning" icon="new_releases" class="my-4">
                            <p>{{$t('discountCouponForm.specificCustomer', {customerName: booking.customer_name, bookingOption: booking.receipt.booking_option ? booking.receipt.booking_option.name : ''})}}</p>
                        </vs-alert>
                    </div>


                    <!-- GENERAL INFORMATION -->
                    <div class="vx-col w-full" v-if="!isSpecificToCustomer">
                        <h4 class="mt-3 opacity-75" >{{$t('discountCouponForm.generalInformation')}}</h4>
                    </div>
                    <div class="vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" v-if="!isSpecificToCustomer">
                        <vs-input :label="$t('discountCouponForm.name')" v-model="currentDiscountCoupon.name" class="w-full required" autocomplete="off"/>
                    </div>
                    <div class="vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" v-if="!isSpecificToCustomer">
                        <vs-input :label="$t('discountCouponForm.code')" v-model="currentDiscountCoupon.code" class="w-full required" autocomplete="off"/>
                    </div>
                    <div class="vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" v-if="!isSpecificToCustomer">
                        <vs-input type="Number" :label="$t('discountCouponForm.maxUsageCount')" v-model="currentDiscountCoupon.max_usage_count" class="w-full" autocomplete="off"/>
                    </div>
                    <div class="vx-col w-full my-3 opacity-50">
                      <hr>
                    </div>
                    <!--// GENERAL INFORMATION //-->


                    <!-- START AND END DATE -->
                    <div class="vx-col w-full">
                        <vs-checkbox color="warning" v-model="dateRangeSection" @change="discountCouponDateRangeFresh">
                            <h4 class="mt-3 opacity-75" >{{ dateRangeSection ? $t('discountCouponForm.dateRangeTitleEnabled') : $t('discountCouponForm.dateRangeTitleDisabled')}}</h4>
                        </vs-checkbox>
                    </div>
                    
                    <div class="vx-col w-full md:mb-2 mt-2 pl-2" v-if="dateRangeSection">
                        <div class="flex w-full">
                            <small class="gray-note">{{$t('discountCouponForm.dateNote')}}</small>
                        </div>
                        <div class="flex w-full">
                            <v-date-picker
                                class="flex w-full"
                                v-model="discountCouponDateRange"
                                mode="datetime"
                                is-range
                                color="orange"
                                :masks="dateFormat"
                                :min-date="minDate"
                                :max-date="maxDate"
                            >
                                <template v-slot="{ inputValue, inputEvents, isDragging }">
                                <div class="flex w-full">

                                    <vs-input
                                        :label="$t('discountCouponForm.startDate')"
                                        class="flex-grow py-1 bg-gray-100 border rounded w-full pr-1"
                                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                        :value="inputValue.start"
                                        v-on="inputEvents.start"
                                        icon="today"
                                    />

                                    <vs-input
                                        :label="$t('discountCouponForm.endDate')"
                                        class="flex-grow py-1 bg-gray-100 border rounded w-full"
                                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                        :value="inputValue.end"
                                        v-on="inputEvents.end"
                                        icon="event"
                                    />
                                </div>
                                </template>
                            </v-date-picker>
                            <div class="pt-2" v-if="discountCouponDateRange.start || discountCouponDateRange.end">
                                <vs-button class="my-5 ml-1" type="border" icon-pack="feather" icon="icon-trash" @click="discountCouponDateRangeFresh"></vs-button>
                            </div>
                        </div>
                    </div>
                    <!--// START AND END DATE //-->

                    <div class="vx-col w-full mt-5">
                        <h4 class="mt-3 opacity-75" >{{$t('discountCouponForm.pricing')}}</h4>
                    </div>
                    
                    <div class="vx-col w-full mb-2 mt-2 pl-3">
                        <div class="container mt-2">
                            <div class="vx-row required">
                                <label class="v-select-label">{{$t('discountCouponForm.discountType')}}</label>
                            </div>
                            <div class="vx-row">
                                <vs-radio class="mr-8 mt-1" v-model="currentDiscountCoupon.discount_type" vs-name="discountCouponForm.discount_type" vs-value="fixed">{{$t('discountCouponForm.fixedDiscount')}}</vs-radio>
                                <vs-radio class="mr-4 mt-1" v-model="currentDiscountCoupon.discount_type" vs-name="discountCouponForm.discount_type" vs-value="percentage">{{$t('discountCouponForm.percentageDiscount')}}</vs-radio>
                            </div>
                        </div>
                    </div>

                    <div class="vx-col w-full md:mb-2 pl-2 mt-2">
                        <vs-input type="Number" :label="$t('discountCouponForm.discount')" v-model="currentDiscountCoupon.discount" class="w-full" :description-text="currentDiscountCoupon.discount_type == 'percentage' ? $t('discountCouponForm.discountInPercentage') : $t('discountCouponForm.discountInRiyals')" autocomplete="off"/>
                    </div>
                    
                    <div class="vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2">
                        <vs-input type="Number" :label="$t('discountCouponForm.minPrice')" v-model="currentDiscountCoupon.min_price" class="w-full" :description-text="$t('discountCouponForm.minPriceDescText')" autocomplete="off"/>
                    </div>
                    <div class="vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" v-if="currentDiscountCoupon.discount_type == 'percentage'">
                        <vs-input type="Number" :label="$t('discountCouponForm.maxPrice')" v-model="currentDiscountCoupon.max_amount" class="w-full" :description-text="$t('discountCouponForm.maxPriceDescText')" autocomplete="off"/>
                    </div>

                    
                    
                    

                    <!-- TARGETING -->
                    <div class="vx-col w-full mt-5" v-if="!(isSpecificToCustomer && booking != null)">
                        <h4 class="mt-3 opacity-75" >{{$t('discountCouponForm.targeting')}}</h4>
                    </div>
                    <div class="vx-col w-full md:mb-2 pl-2 mt-2" v-if="!(isSpecificToCustomer && booking != null)">
                        <label class="v-select-label">{{$t('discountCouponForm.targetBookingOptions')}}</label>
                        <v-select
                            multiple
                            class="v-select-background"
                            v-model="selectedBookingOptions"
                            :options="bookingOptionsList"
                            label="name"
                            :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        />
                    </div>
                    <div class="vx-col w-full md:mb-2 pl-2 mt-2" v-if="!(isSpecificToCustomer && booking != null) && currentDiscountCoupon.targeted_users && currentDiscountCoupon.targeted_users.length > 0">
                        <label class="v-select-label">{{$t('discountCouponForm.targetCustomer')}}</label>
                        <ul class="flex flex-wrap">
                            <li v-for="user in currentDiscountCoupon.targeted_users" :key="user.id" class="mt-2 mr-6">
                                <vs-checkbox v-model="selectedTargetedUsers" :vs-value="user.id">{{user.full_name.substring(0, 2)+"*****"}}</vs-checkbox>
                            </li>
                        </ul>
                        <div class="vx-col w-full md:mb-2">
                            <vs-alert color="warning" icon="warning" class="mt-2 mb-4">
                                <small>{{$t('discountCouponForm.specificTargetedCustomerNote')}}</small>
                            </vs-alert>
                        </div>
                    </div>
                    <!--// TARGETING //-->

                    <div class="vx-col w-full md:mb-2 mt-2">
                        <div class="vx-col w-full md:mb-2" v-if="isSpecificToCustomer && booking != null">
                            <vs-alert color="success" icon="info" class="my-4">
                                <p>{{$t('discountCouponForm.specificCustomerBeforeSaveNote')}}</p>
                            </vs-alert>
                        </div>
                        <div class="flex w-full justify-end">
                            <vs-button icon-pack="feather" icon="icon-check" @click="submitForm">{{$t('discountCouponForm.sendOffer')}}</vs-button>

                        </div>
                    </div>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import axios from '@/axios'
import vSelect from 'vue-select'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'

export default {
    name: 'discount-coupon-form-popup',
    props: {
        discountCouponPopupActive: {
            type: Boolean,
            defult: false
        },
        currentDiscountCoupon: {
            type: Object,
            defult: null
        },
        isEditDiscountCoupon: {
            type: Boolean,
            default: false
        },
        isSpecificToCustomer: {
            type: Boolean,
            default: false
        },
        booking: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            selectedFacilities: [],
            // date-picker-variables
            discountCouponDateRange: {
                start: null,
                end: null,
            },
            minDate: "2022-10-01",
            maxDate: "2030-01-01",
            dateFormat: {
                input: 'YYYY-MM-DD HH:mm'
            },
            dateRangeSection: false,
            // date-picker-variables //

            selectedBookingOptions: [],
            selectedTargetedUsers: [],
        }
    },
    components: {
        vSelect,
        Datepicker
    },
    computed: {
        isActive: {
            get() {
                return this.discountCouponPopupActive
            },
            set(newState) {
                return newState
            }
        },
        currentVenueId () {
            return this.$store.getters.currentVenueId;
        },
        bookingOptionsList () {
            return this.$store.state.general.bookingOptionsList;
        },
    },
    created () {
        this.$store.dispatch('general/getBookingOptionsList', this.currentVenueId)
    },
    methods: {
        submitForm() {
            this.$store.dispatch('loader/loaderOn')
            
            let request = new FormData();

            for (var key in this.currentDiscountCoupon ) {
                if(this.currentDiscountCoupon[key] != null)
                    request.append(key, this.currentDiscountCoupon[key]);
            }

            if(this.discountCouponDateRange.start) request.append('start_date', moment(this.discountCouponDateRange.start).format(this.dateFormat.input)); else request.append('start_date', '');
            if(this.discountCouponDateRange.end) request.append('end_date', moment(this.discountCouponDateRange.end).format(this.dateFormat.input)); else request.append('end_date', '');

            console.log('booking:',this.booking);
            console.log('specific to customer:', this.isSpecificToCustomer);
            if(this.isSpecificToCustomer && this.booking != null) { // to add the customer and the booking option to the discount coupon
                if(this.booking.customer_id) request.append('customers[]', this.booking.customer_id);
                if(this.booking.option_id) request.append('booking_options[]', this.booking.option_id);
                request.append('booking_id', this.booking.id)
            } else {
                if(this.selectedBookingOptions && this.selectedBookingOptions.length > 0) this.selectedBookingOptions.forEach(bookingOption => request.append('booking_options[]', bookingOption.id)); else request.append('booking_options', []);
                if(this.selectedTargetedUsers && this.selectedTargetedUsers.length > 0) this.selectedTargetedUsers.forEach(user => request.append('customers[]', user)); else request.append('customers', []);
            }
            
            let url = this.isEditDiscountCoupon ? `/venues/${this.currentVenueId}/discountCoupons/${this.currentDiscountCoupon.id}` : `/venues/${this.currentVenueId}/discountCoupons`;
            
            axios.post(url, request)
                .then((response) => {
                    this.closePopup();
                    this.$emit('getLeads')  
                    this.$vs.notify({
                        color:'success',
                        text: response.data.success_message || 'تم الحفظ بنجاح'
                    })
                }).catch(error => {
                    this.errorsDisplay(error);
                })
                .finally(() => {
                    this.$store.dispatch('loader/loaderOff')
                }); // end of axios
        },
        
        closePopup() {
            if(!(this.isSpecificToCustomer && this.booking != null)) this.$parent.getDiscountCoupons(); // only get the coupons if we are in discount coupons crud page
            this.$emit('update:discountCouponPopupActive', false);
        },
        errorsDisplay(error) {
            let errs = error.response.data.errors ? Object.keys(error.response.data.errors).map((key) => error.response.data.errors[key]) : []
            let text = '';
            errs.forEach(err => {
                text += '<li>'+err[0]+'</li>'
            });
            this.$vs.notify({
                color:'danger',
                type: 'error',
                title: 'حدث خطأ ما!',
                text: '<ul>'+text+'</ul>'
            });
        },
        discountCouponDateRangeFresh() {
            this.discountCouponDateRange = { start: null, end:null};
        }
    },
    watch: {
        currentDiscountCoupon(newVal) {
            this.selectedBookingOptions = newVal.booking_options;
            if(newVal.targeted_users) this.selectedTargetedUsers = newVal.targeted_users.map(user => user.id)
            if(newVal.start_date != null) this.discountCouponDateRange = {start: newVal.start_date, end: newVal.end_date}; else this.discountCouponDateRangeFresh();
            if(newVal.start_date != null) this.dateRangeSection = true; else this.dateRangeSection = false;
        },
        discountCouponDateRange: function() {
            this.currentDiscountCoupon.start_date = this.discountCouponDateRange.start;
            this.currentDiscountCoupon.end_date = this.discountCouponDateRange.end;
        },
    }
}
</script>

<style lang="css">

.required label::after {
    content: "*";
    color: red;
}
</style>
