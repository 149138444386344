<template>
  <div>
    <div class="flex flex-wrap">
        <vs-input :label="$t('discountCouponForm.name')" v-model="discountCoupon.name" class="w-full pr-1 mb-3" disabled/>
        <vs-input :label="$t('discountCouponForm.code')" v-model="discountCoupon.code" class="w-1/2 pr-1 mb-3" disabled/>
        <vs-input :label="$t('discountCouponForm.maxUsageCount')" v-model="discountCoupon.max_usage_count" class="w-1/2 pr-1 mb-3" disabled/>

        <div class="w-full flex" v-if="discountCoupon.has_date_range == 'yes'">
            <vs-input :label="$t('discountCouponForm.startDate')" v-model="discountCoupon.start_date" class="w-1/2 pr-1 mb-3" disabled/>
            <vs-input :label="$t('discountCouponForm.endDate')" v-model="discountCoupon.end_date" class="w-1/2 pr-1 mb-3" disabled/>
        </div>

        <vs-input class="w-full lg:w-1/2 md:mb-2 pl-2 mt-2" v-if="discountCoupon.discount_type == 'fixed'" :label="$t('discountCouponForm.discountType')" :value="$t('discountCouponForm.fixedDiscount')" disabled/>
        <vs-input class="w-full lg:w-1/2 md:mb-2 pl-2 mt-2" v-if="discountCoupon.discount_type == 'percentage'" :label="$t('discountCouponForm.discountType')" :value="$t('discountCouponForm.percentageDiscount')" disabled/>

        <vs-input class="w-full lg:w-1/2 md:mb-2 pl-2 mt-2" :label="$t('discountCouponForm.discount')" v-model="discountCoupon.discount" :description-text="discountCoupon.discount_type == 'percentage' ? $t('discountCouponForm.discountInPercentage') : $t('discountCouponForm.discountInRiyals')" disabled/>


        <vs-input class="w-full lg:w-1/2 md:mb-2 pl-2 mt-2" v-if="discountCoupon.min_price" :label="$t('discountCouponForm.minPrice')" v-model="discountCoupon.min_price" :description-text="$t('discountCouponForm.minPriceDescText')" disabled/>

        <vs-input class="w-full lg:w-1/2 md:mb-2 pl-2 mt-2" v-if="discountCoupon.max_amount" :label="$t('discountCouponForm.maxPrice')" v-model="discountCoupon.max_amount" :description-text="$t('discountCouponForm.maxPriceDescText')" disabled/>
    </div>
  </div>
</template>

<script>
export default {
    name: 'discount-coupon-details',
    props: {
        discountCoupon: {
            type: Object,
            defult: null
        },
        
    },
}
</script>

<style>

</style>